<template>
  <div
    :class="isOpened ? 'menu-block active' : 'menu-block'"
    @click="isOpened = !isOpened"
  >
    <div class="trigger">
      <span>{{ triggertext }}</span>
      <i class="el-icon-caret-bottom"></i>
    </div>
    <div class="content" v-if="isOpened">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-block',
  props:['triggertext'],
  data() {
    return {
      isOpened: false
    }
  }
}
</script>

<style scoped>
.menu-block {
  color: #fff;
}

.menu-block * {
  box-sizing: border-box;
}

.menu-block.active {
  background-color: darkslateblue;
}

.menu-block .trigger {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 20px;
  /* transition: transform 5s; */
}

.menu-block.active .trigger i {
  transform: rotate(180deg);
}

.menu-block .content {
  color: #fff;
  font-size: 16px;
}

.menu-block .content li:hover,
.menu-block .trigger:hover {
  background-color: rgb(91, 116, 238);
  cursor: pointer;
}

.menu-block .content a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  padding: 10px 20px;
  padding-left: 40px;
}
</style>

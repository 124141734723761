<template>
  <div class="template-wrapper">
    <div class="template-content">
      <navigation />
      <div class="template-content-view">
        <header-template />
        <div class="content">
          <div class="content-header">
            <h2>

              <router-link :to="backlink" v-if="backlink">
                <i class="el-icon-back"></i>
              </router-link>
              {{ title }}
            </h2>

            <router-link :to="newlink" v-if="newlink">
              <el-button type="primary">
                {{ newtext }}
              </el-button>
            </router-link>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigation from '@/components/templatenavigation.vue'
import headerTemplate from '@/components/templateheader.vue'

export default {
  name: 'admintemplate',
  components: {
    navigation,
    headerTemplate,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    newtext: {
      type: String
    },
    newlink: {
      type: String
    },
    backlink: {
      type: String
    }
  },
}
</script>

<style scoped>
.template-wrapper,
.template-wrapper * {
  box-sizing: border-box;
}

.template-wrapper {
  background-color: #edebf7;
  padding: 20px;
}

.template-content {
  background-color: #fff;
  min-height: calc(100vh - 80px);
  border-radius: 20px;
  border: 1px solid #F4F5F8;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.template-content-view .content {
  padding: 20px;
}

.template-content-view .content .content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template-content-view .content h2 {
  padding: 5px 0;
  margin: 0;
  color: #5871EE;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.template-content-view .content h2 i {
  margin-right: 10px;
}

@media screen and (min-width: 728px) {
  .template-content {
    display: grid;
    grid-template-columns: minmax(220px, auto) 1fr;
    grid-template-rows: 1fr;
  }
}

@media screen and (min-width: 1024px) {
  .template-wrapper {
    padding: 40px;
  }

  .template-content {
    max-width: 928px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

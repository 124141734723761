<template>
  <nav class="menu">
    <ul>
      <li>
        <router-link to="/admin/cupon">
          cupons
        </router-link>
      </li>

      <li>
        <router-link to="/admin/color">
          cores
        </router-link>
      </li>

      <li>
        <router-link to="/admin/user/new">
          cadastrar admin
        </router-link>
      </li>

      <menublock triggertext="produtos">
        <li>
          <router-link to="/admin/products/categories">
            categorias
          </router-link>
        </li>

        <li>
          <router-link to="/admin/products/subcategories">
            subcategorias
          </router-link>
        </li>
        <li>
          <router-link to="/admin/products">
            lista de produtos
          </router-link>
        </li>
      </menublock>


      <menublock triggertext="blog">
        <li>
          <router-link to="/admin/posts/categories">
            categorias de posts
          </router-link>
        </li>
        <li>
          <router-link to="/admin/posts">
            posts
          </router-link>
        </li>
      </menublock>

    </ul>
  </nav>
</template>

<script>
import menublock from '@/components/menu-block.vue'

export default {
  name: 'templatenavigation',
  components: {
    menublock
  }
}
</script>

<style scoped>
.menu {
  background-color: #4762ec;
  border-radius: 20px;
}

.menu * {
  box-sizing: border-box;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 40px;
}

.menu ul li {
  color: #fff;
  font-size: 16px;
}

.menu ul li.active {
  background-color: darkslateblue;
}

.menu ul li:hover {
  background-color: rgb(91, 116, 238);
  cursor: pointer;
}

.menu ul li a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  padding: 10px 20px;
}
</style>

<template>
  <admin-template
    title="produto"
    backlink="/admin/products"
  >
    <form>
      <fieldform text="nome">
        <el-input v-model="formData.name" ></el-input>
      </fieldform>

      <div class="col-2">
        <fieldform text="categoria">
        <el-select
          v-model="formData.categoryId"
          placeholder="selecione"
          @change="handleCategoryChange"
        >
          <el-option
            v-for="item in categoryTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        </fieldform>

        <fieldform text="subcategoria">
          <el-input v-model="subcategory.name" ></el-input>
        </fieldform>
      </div>

      <fieldform text="código">
        <el-input v-model="formData.code"></el-input>
      </fieldform>

      <fieldform text="feito com">
        <el-input v-model="formData.madeWith"></el-input>
      </fieldform>

      <fieldform text="detalhes">
        <el-input v-model="formData.printDetails"></el-input>
      </fieldform>

      <fieldform text="métricas e peso">
        <el-input v-model="formData.metricsAndWeight"></el-input>
      </fieldform>

      <fieldform text="cuidados e dicas com o produto">
        <el-input v-model="formData.productCareTips"></el-input>
      </fieldform>

      <div class="col-3">

        <fieldform text="ordem">
          <el-input-number
            v-model="formData.orderToShow"
            :min="1"
            :max="1000"
          ></el-input-number>
        </fieldform>

        <fieldform text="dimensões de seleção">
          <el-switch
            v-model="formData.requireSelectDimensions"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </fieldform>

        <fieldform text="seleção de cor">
          <el-switch
            v-model="formData.requireSelectColor"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </fieldform>
      </div>

      <upload
        :images="formData.images"
        @onRemoveImage="removeImage"
        @onAddImage="addImage"
      />

      <div class="submit">
        <el-button
          @click="submit"
          type="primary"
        >
          salvar
        </el-button>
      </div>
    </form>
  </admin-template>
</template>


<script>
import api from '@/services/api'
import adminTemplate from '@/components/admintemplate.vue'
import fieldform from '@/components/fieldform.vue'
import upload from '@/components/upload.vue'

export default {
  name: 'product-handle',
  components: {
    adminTemplate,
    fieldform,
    upload,
  },
  data() {
    return {
      image: '',
      formData: {
        name: null,
        categoryId: null,
        subCategoryId: '',
        code: null,
        madeWith: null,
        printDetails: null,
        metricsAndWeight: null,
        productCareTips: null,
        orderToShow: 0,
        requireSelectDimensions: true,
        requireSelectColor: true,
        images: []
      },
      categoryTypes: [{
        id: 1,
        name: 'carregando opções'
      }],
      subcategory: {
        id: 1,
        name: 'nenhuma opção disponível'
      },
      alterId: this.$route.params.id || null,
    }
  },
  methods: {
    submit() {
      const { formData } = this

      // ALTER DATA
      if (this.alterId) {
        api.put(`/backoffice/product/${this.alterId}`, {
          ...formData,
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'produto alterado com sucesso!',
              type: 'success'
            });

            this.$router.push('/admin/products')
            return res
          })
          .catch(({ response }) => {
            const { data } = response;
            if (data.message) {
              this.showMessage({
                message: data.message,
                type: 'error'
              })
            }
            else {
              data.validations.body.forEach(item => {
                this.showMessage({
                  message: item.message,
                  type: 'error'
                })
              })
            }

            this.$router.push('/admin/products')
            return response
          })
      }
      // NEW DATA
      else {
        console.log(this.subcategory)
        api.post(`backoffice/product`, {
          ...formData,
          subCategoryId: this.subcategory.id,
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'produto cadastrado com sucesso!',
              type: 'success'
            });

           this.$router.push('/admin/products')
            return res
          })
          .catch(({ response }) => {
            const { data } = response;
            if (data.message) {
              this.showMessage({
                message: data.message,
                type: 'error'
              })
            }
            else {
              data.validations.body.forEach(item => {
                this.showMessage({
                  message: item.message,
                  type: 'error'
                })
              })
            }
          })
      }
    },
    getProduct(id) {
      api.get(`/backoffice/product/${id}`, {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({data}) => {
          this.subcategory = data.subCategory
          this.formData = data
        })
        .catch(err => console.log(err))
    },
    getCategories() {
      this.loading = true
      api.get('/backoffice/product/category', {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({ data }) => {
          if (data.length === 0) {
            this.categoryTypes = [{
              id: 0,
              name: 'nenhuma opção disponivel'
            }]
          }
          else {
            this.categoryTypes = data
          }
          this.loading = false
        })
        .catch(err => console.log(err))
    },
    getSubcategoriesByCategoryId(id) {
      api.get(`/backoffice/product/subCategory/category/${id}`, {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({ data }) => {
          this.subcategory = data
          this.loading = false
        })
        .catch(err => console.log(err))
    },
    showMessage(payload) {
      this.$message(payload)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleCategoryChange(categoryId) {
      this.getSubcategoriesByCategoryId(categoryId)
    },
    removeImage(index) {
      this.formData.images.splice(index, 1)
    },
    addImage(image) {
      this.formData.images.push(image)
    }
  },
  mounted() {
    this.getCategories()
    if (this.alterId) {
      this.getProduct(this.alterId)
    }
  },
}
</script>

<style scoped>
form {
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 10px;
  margin-top: 23px;
}

.el-select {
  width: 100%;
}

.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}

.upload {
  margin-top: 20px;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>

<template>
  <header>
    <div>&nbsp;</div>

    <div class="user-block">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTcZsL6PVn0SNiabAKz7js0QknS2ilJam19QQ&usqp=CAU" />
          {{ name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-circle-close"
          >
            <span @click="logoff">logoff</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'headertemplate',
  data() {
    return {
      name: null
    }
  },
  methods: {
    logoff() {
      localStorage.removeItem('token')
      this.$router.push('/')
    },
    getName() {
      this.loading = true
      api.get('/backoffice/user/me', {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({ data }) => {
          this.name = data.user.name
          this.loading = false
        })
        .catch(err => console.log(err))
    }
  },
  computed: {
    token () {
      return localStorage.token
    }
  },
  mounted() {
    this.getName()
  }
}
</script>

<style scoped>
header {
  height: 48px;
  border-bottom: 1px solid #CDCDE2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  padding-left: 20px;
  padding-right: 40px;
}

header .el-dropdown {
  font-size: 14px;
}

header .el-dropdown-link {
  display: flex;
  align-items: center;
}

header img {
  width: 22px;
  margin-right: 8px;
}

header .user-block {
  display: flex;
  align-items: center;
  height: 100%;
}

header .user-block:hover {
  cursor: pointer;
}
</style>

<template>
  <div>
    <h2>imagens do produto</h2>

    <div class="grid">
      <div class="item" v-for="(image, index) in images" :key="image">
        <img :src="image" alt="" />
        <div class="remove-icon" @click="() => removeImage(index)">
          <strong>x</strong>
        </div>
      </div>
      <div class="uploaditem">
        <span>+</span>
        <input @change="handleImage" type="file" accept="" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'upload-component',
  props: {
    images: Array,
  },
  methods: {
    removeImage(index) {
      this.$emit('onRemoveImage', index)
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const res = e.target.result;
        // this.image = res;
        this.postUploadedImage(this.base64Mask(res))
      };
      reader.readAsDataURL(fileObject);
    },
    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },
    base64Mask(fileStr) {
      const url = fileStr.split(',');
      const [head, ...rest] = url
      const extension =
        head
          .replace('data:', '')
          .replace(';base64', '')

      return {
        extension,
        base64: rest[0]
      }
    },
    postUploadedImage(base64) {
      api.post(`backoffice/images/upload`, {
        ...base64,
      },
        {
          headers: {
            'x-access-token': localStorage.token,
            'Content-Type': 'application/json',
          },
          mode: 'no-cors'
        })
        .then(res => {
          const { data } = res
          this.$emit('onAddImage', data.Location)
        })
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 15px;
  margin-top: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 1rem;
}

.grid > div {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
}

.grid > div.item .remove-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  top: -10px;
  right: -10px;
}

.grid > div.item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid > div.uploaditem {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  position: relative;
}

.grid > div.uploaditem input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.grid > div.uploaditem span {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 15px);
  font-size: 44px;
  transition: 2s opacity;
}

.grid > div.uploaditem:hover {
  opacity: .7;
  cursor: pointer;
}

.grid > div::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.grid > div {
  /* ... */
  display: grid;
}

.grid > div::before,
.grid > div > img {
  grid-area: 1 / 1 / 2 / 2;
}
</style>


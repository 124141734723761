<template>
  <div class="field">
    <label>{{ text }}:</label>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'fieldform',
  props: {
    text: {
      String,
      required: true,
    }
  },
}
</script>

<style scoped>
.field {
  margin-top: 16px;
}

.field label {
  display: block;
  margin-bottom: 6px;
}
</style>
